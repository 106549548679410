/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/styles/main.css"

const shouldUpdateScroll = () => {
    return false
}

export { shouldUpdateScroll }
